import { Link } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import Instagram from "../../assets/footer/instagram.svg"
import Youtube from "../../assets/footer/youtube.svg"
import Twitter from "../../assets/footer/twiter.svg"
import Facebook from "../../assets/footer/facebook.svg"
import Linkedin from "../../assets/footer/linkedin.svg"
import Estori from "../../assets/first/Estori.svg"

const fs = require("../../css/layout/footer.module.css")

const Footer = () => {

  const goFacebook = () => window.open("https://www.facebook.com/estori.col", "_blank")
  const goYoutube = () => window.open("https://www.youtube.com/channel/UCgwvgOjs0OsH5-ZNwCwHjnw", "_blank")
  const goInstagram = () => window.open("https://www.instagram.com/estori.co/", "_blank")
  const goLinkedin = () => window.open("https://www.linkedin.com/company/estori-co", "_blank")
  const goTwitter = () => window.open("https://twitter.com/estori_co", "_blank")
  const goTerms = () => window.open("/terminos&condiciones/", "_blank")
  const goPrivacy = () => window.open("/politica-de-privacidad/", "_blank")
  const goHelpCenter = () => window.open("https://ayuda.estori.co/", "_blank")
  const goBlogs = () => window.open("/blogs/", "_blank")

  return (
    <footer
      className={`container col-12 border-none p-5 clearfix`}
      style={{ background: "#15357A" }}
    >
      <Link rel="prev" className="margin-zero pt-0 " to="/">
        <Estori />
      </Link>
      <div className="row mt-4 mb-5">
        <div className=" col-xs-12 col-lg-4 pl-4 pl-2-r mb-xs-3">
          <p className="text-justify  text-white">
            La plataforma de eCommerce para marcas <br /> reinventistas de Latam 💙
          </p>
{/*           <p className="text-white mt-4">
            Regístrese para recibir actualizaciones
          </p>
          <div className="d-flex">
            <input
              className="email-send-estori"
              placeholder="Correo electrónico"
            />
            <button className="btn btn-free ml-4">Enviar</button>
          </div> */}
          <div className="d-flex  mt-4">
            <span className=" btn-link-footer cursor-pointer" onClick={goFacebook}>
              <Facebook />
            </span>
            <span className=" btn-link-footer ml-2 cursor-pointer" onClick={goYoutube}>
              <Youtube />
            </span>
            <span className=" btn-link-footer ml-2 cursor-pointer" onClick={goTwitter}>
              <Twitter />
            </span>
            <span className=" btn-link-footer ml-2 cursor-pointer" onClick={goLinkedin}>
              <Linkedin />
            </span>
            <span className=" btn-link-footer ml-2 cursor-pointer" onClick={goInstagram}>
              <Instagram />
            </span>
          </div>
        </div>
{/*         <div className="col-xs-12 ml-5 margin-zero   m-xs-3 col-lg-2 ">
          <span className="title-span-footer">Producto</span>
          <ul className="items-footer mt-3">
            <li>Gestión de ventas</li>
            <li>Stock de inventario</li>
            <li>Pagos online</li>
            <li>Cupones y descuentos</li>
          </ul>
        </div> */}

        <div className="col-xs-12 m-xs-3 col-lg-2">
          <span className="title-span-footer">Legal</span>
          <ul className="items-footer mt-3">
            <li className="cursor-pointer" onClick={goTerms}>Términos y condiciones</li>
            <li className="cursor-pointer" onClick={goPrivacy}>Politíca de privacidad</li>
          </ul>
        </div>

        <div className="col-xs-12 m-xs-3 col-lg-2">
          <span className="title-span-footer">Recursos</span>
          <ul className="items-footer mt-3">
            <li className="cursor-pointer" onClick={goHelpCenter}>Centro de ayuda</li>
            <li className="cursor-pointer" onClick={goBlogs}>Blogs</li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
