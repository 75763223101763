import clsx from "clsx"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React, { useState, useEffect } from "react"
import ArrowDown from "../../assets/first/arrowDown.svg"
import Menu from "../../assets/first/menu.svg"
import Estori from "../../assets/first/Estori.svg"
import ArrowTwo from "../../assets/fourth/arrowDown.svg"
import "./style.scss"

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "home/logo-estori.png" }) {
      childImageSharp {
        fixed(width: 126) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
interface InHeader {
  isSticky: boolean
  setMobile: (value: boolean) => void
  mobile: boolean
}

const Header: React.FC<InHeader> = ({ isSticky, setMobile, mobile }) => {
  const [productA, setProductA] = useState<boolean>(false)
  const [agentsA, setAgentsA] = useState<boolean>(false)
  const [resourceA, setResourceA] = useState<boolean>(false)
  const [arrowOption, setArrowOption] = useState('');
  const [solutionA, setSolutionA] = useState<boolean>(false)

  const itemMobile = [
    /*     {
          title: "Producto",
          arrow: true,
        }, */
    /* {
      title: "Precios",
      arrow: false,
      url: "/planes"
    }, */

    /*     {
          title: "Soluciones",
          arrow: true,
        }, */
    {
      title: "Recursos",
      arrow: true,
    },
    /*     {
          title: "Para empresas",
          arrow: false,
        }, */
  ]

  const goTo = (url: string) => window.location.href = url

  const product = () => {
    return (
      <div className="row w-100 overflow-hidden">
        <div className="col-one col-3">
          <div className="d-flex flex-column">
            <span>Producto</span>
            <p>Las funcionalidades que esperabas <br /> y las que te sorprenderán. </p>
          </div>
        </div>
        <div className="col p-4">
          <div className="row">
            <div className="col text-items-popover">
              <h2>FUNCIONALIDADES CORE</h2>
              <div className="mt-1">
                <span>Ecommerce</span>
                <p>Stress-free promotions solutios</p>
              </div>
              <div className="mt-1">
                <span>CDP</span>
                <p>Stress-free promotions solutios</p>
              </div>
              <div className="mt-1">
                <span>CRM</span>
                <p>Stress-free promotions solutios</p>
              </div>
            </div>
            <div className="col text-items-popover ">
              <h2>FUNCIONALIDADES CORE</h2>
              <div className="mt-1">
                <span>No code</span>
                <p>Stress-free promotions solutios</p>
              </div>
              <div className="mt-1">
                <span>Headless Commerce</span>
                <p>Stress-free promotions solutios</p>
              </div>
              <div className="mt-1">
                <span>PWA</span>
                <p>Stress-free promotions solutios</p>
              </div>
              <div className="mt-1">
                <span>Multitenant</span>
                <p>Stress-free promotions solutios</p>
              </div>
            </div>
            <div className="col text-items-popover">
              <h2>INTEGRACIONES</h2>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Solutions = () => {
    return (
      <div className="row w-100 overflow-hidden">
        <div className="col-one col-3">
          <div className="d-flex flex-column">
            <span>Soluciones</span>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
          </div>
        </div>
        <div className="col p-4">
          <div className="row">
            <div className="col text-items-popover">
              <h2>Industrias</h2>
              <div className="mt-1">
                <span>Retail</span>
              </div>
              <div className="mt-1">
                <span>Grocery</span>
              </div>
              <div className="mt-1">
                <span>Moda</span>
              </div>
              <div className="mt-1">
                <span>Farmacia</span>
              </div>
            </div>
            <div className="col text-items-popover ">
              <h2>FUNCIONALIDADES CORE</h2>
              <div className="mt-1">
                <span>Emprendedores</span>
              </div>
              <div className="mt-1">
                <span>Empresas</span>
              </div>
            </div>
            <div className="col text-items-popover">
              <h2>Segmentos</h2>
              <div className="mt-1">
                <span>B2C</span>
              </div>
              <div className="mt-1">
                <span>B2B</span>
              </div>
              <div className="mt-1">
                <span>D2C</span>
              </div>
              <div className="mt-1">
                <span>Omnicanal</span>
              </div>
            </div>
            <div className="col text-items-popover">
              <h2>Marketplace</h2>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Resources = () => {
    return (
      <div className="row w-100 overflow-hidden">
        <div className="col-one col-6">
          <div className="d-flex flex-column">
            <span>Recursos</span>
            <p className="cursor-pointer" onClick={() => goTo('https://portal.estori.co/login')}>Apóyate con nuestros recursos para crecer tu marca.</p>
          </div>
        </div>
        <div className="col col-6 p-4">
          <div className="row">
            <div className="col text-items-popover">
              <div className="mt-1">
                <span className="cursor-pointer" onClick={() => goTo('/blogs')}>Blog</span>
              </div>
              <div className="mt-1">
                <span className="cursor-pointer" onClick={() => goTo('https://ayuda.estori.co')}>Centro de ayuda</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Agents = () => {
    return (
      <div className="row w-100 overflow-hidden">
        <div className="col-one col-6">
          <div className="d-flex flex-column">
            <span>Recursos</span>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
          </div>
        </div>
        <div className="col p-4">
          <div className="row">
            <div className="col text-items-popover">
              <div className="mt-1">
                <span>Dropshipping</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const handlerOption = (value: any) => {
    switch (value) {
      case 'Recursos':
        return (
          <ul className="link-mobile-text mt-2">
            <li onClick={() => goTo('/blogs')}>Blog <i className="bi bi-box-arrow-up-right"></i></li>
            <li onClick={() => goTo('https://ayuda.estori.co')}>Centro de ayuda <i className="bi bi-box-arrow-up-right"></i></li>
          </ul>
        )

      default:
        return (<></>)
    }
  }

  const handlerMenu = (value: string) => {
    setArrowOption(value)
    if (value === 'Recursos') {
      setResourceA(!resourceA)
    }

  }

  const menuMobile = () => (
    <div className="header-mobile">
      <div className="d-flex flex-column mt-2">
        {itemMobile.map((item: any, i: any) => (
          <div className="d-flex flex-column" key={i}>
            <div className="d-flex justify-content-between align-items-center">
              <span
                onClick={() => item.url && goTo(item.url)}
                className={clsx(
                  "text-m-link",
                  item.title === "Solicitar Demo"
                    ? "color-orange-m"
                    : "color-blue-m"
                )}
              >
                {item.title}
              </span>
              {item.arrow && <span className="cursor-pointer" onClick={() => handlerMenu(item.title)}> <ArrowTwo /></span>}
            </div>
            {arrowOption === item.title && <> {resourceA && handlerOption(arrowOption)} </>}
            <hr />
          </div>
        ))}
        <button
          onClick={() => goTo('https://portal.estori.co/login')}
          className="btn-estori btn-create-store pl-4 pr-4 w-100 mb-2"
        >
          Iniciar sesión
        </button>
        {
          !window.location.pathname.includes("demo") &&
          <button
            onClick={() => goTo('/demo')}
            className="btn-estori  btn-login  pl-4 pr-4 w-100"
          >
            Solicitar Demo
          </button>
        }
      </div>
    </div>
  )
  return (
    <>
      <header
        className={`header_area animated navbar navbar-expand-md padding_for_large_screens ${isSticky ? "sticky slideInDown" : ""
          }`}
      >
        <Link rel="prev" className="navbar-brand pt-0" to="/">
          <Estori />
        </Link>
        <button
          className="navbar-toggler navbar-Menu"
          type="button"
          onClick={() => setMobile(!mobile)}
        >
          <Menu />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ml-auto align-items-center ">
            {/*             <div className="popover__wrapper-product mx-3">
              <span
                className=" text-ls pt-0 cursor-pointer "
                onClick={() => setProductA(!productA)}
              >
                <div className="d-flex justify-content-between align-items-center link-span">
                  {" "}
                  Producto <ArrowDown />{" "}
                </div>
              </span>
              <div
                className={clsx(
                  "popover__content-product",
                  productA && "popover__content-product-active"
                )}
              >
                {product()}
              </div>
            </div> */}

            {/*             <div className="popover__wrapper-solution mx-3">
              <span
                className=" text-ls pt-0 cursor-pointer "
                onClick={() => setSolutionA(!solutionA)}
              >
                <div className="d-flex justify-content-between align-items-center  link-span">
                  {" "}
                  Soluciones <ArrowDown />{" "}
                </div>
              </span>
              <div
                className={clsx(
                  "popover__content-solution",
                  solutionA && "popover__content-solution-active"
                )}
              >
                {Solutions()}
              </div>
            </div> */}
            <div className="popover__wrapper-solution mx-3">
              <span
                className=" text-ls pt-0 cursor-pointer "
              >
                <div className="d-flex justify-content-between align-items-center  link-span">
                  {" "}
                  Recursos <ArrowDown />{" "}
                </div>
              </span>
              <div
                className={clsx(
                  "popover__content-solution",
                  solutionA && "popover__content-solution-active"
                )}
                style={{ borderRight: "1px solid #ffff" }}
              >
                {Resources()}
              </div>
            </div>
            {/*             <Link rel="prev" className="text-ls pt-0 " to="/agentes">
              <span className="link-span"> agentes</span>
            </Link> */}
            {
              /* !window.location.pathname.includes("demo") &&

              <button
                onClick={() => goTo('/demo')}
                className="btn-estori  btn-login  pl-4 pr-4 ml-5"
              >
                Solicitar Demo
              </button> */
            }
            <button
              onClick={() => goTo('https://portal.estori.co/login')}
              className="btn-estori  btn-create-store  pl-4 pr-4"
              style={{ marginLeft: '1rem' }}
            >
              Iniciar sesión
            </button>
          </div>
        </div>
      </header>
      {mobile && menuMobile()}
    </>
  )
}

export default Header
