// If you don't want to use TypeScript you can delete this file!
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"

// import Footer from "./../components/footer/index"
import Footer from "./footer/footer"
import Header from "./header"

interface LayoutProps {
  children: any
  bodyAttributes?: {
    dataTarget: ".navbar"
    dataOffset: "50"
    dataSpy: "scroll"
  }
}

const Layout: React.FC<LayoutProps> = ({ children, bodyAttributes }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isSticky, setSticky] = useState(false)
  const [mobile, setMobile] = useState<boolean>(false)
  const handleScroll = () => {
    const refHeader: any = ref.current

    if (refHeader) {
      const lastScroll = JSON.parse(
        localStorage.getItem("scrollTop") || refHeader.scrollTop
      )
      const currentScroll = window.pageYOffset
      if (currentScroll <= 0) {
        setSticky(false)
        return
      }
      if (currentScroll > lastScroll) {
        setSticky(false)
        setMobile(false)
        
      } else if (currentScroll < lastScroll) {
        setSticky(true)
      }
      localStorage.setItem("scrollTop", JSON.stringify(currentScroll))
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])
  return (
    <div className="border-none padding-space-right overflow-hidden" ref={ref}>
      {/*  Header Start */}
      <Header isSticky={isSticky} mobile={mobile} setMobile={setMobile} />
      {/*  Header End */}

      {/* Content Start */}
      {children}
      {/* Content End */}

      {/*  Footer Start */}
      <Footer />
      {/*  Footer End */}

      <Helmet bodyAttributes={bodyAttributes}>
        {/* <script
        src={withPrefix("../../js/jquery.min.js")}
        type="text/javascript"
      />
      <script
        src={withPrefix("../../js/popper.min.js")}
        type="text/javascript"
      />
      <script
        src={withPrefix("../../js/bootstrap.min.js")}
        type="text/javascript"
      /> */}
      </Helmet>
    </div>
  )
}

export default Layout
